<template>
  <v-scroll-y-transition>
    <div
      v-if="show"
      :class="{ 'yellow--text': checkMaxCC }"
    >{{ live }}</div>
  </v-scroll-y-transition>
</template>

<script>
export default {
  name: 'AnimationChangeLive',
  inheritAttrs: false,
  props: {
    live: {
      type: String | Number,
      default: 0
    },
    maxConcurrency: {
      type: String | Number,
      default: 0
    }
  },
  data: () => ({
    show: true
  }),
  computed: {
    checkMaxCC() {
      if (!this.maxConcurrency) {
        return false
      }

      return +this.live >= +this.maxConcurrency
    }
  },
  methods: {
    handleTransition() {
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 100)
    }
  },
  watch: {
    live: {
      handler(live, oldLive) {
        if (live !== oldLive) {
          this.handleTransition()
        }
      }
    }
  }
}
</script>
